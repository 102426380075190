import { createClient } from "@supabase/supabase-js";

// Supabase-Konfiguration
const SUPABASE_URL = "https://iabdippnknqmozheiulb.supabase.co";
const SUPABASE_KEY = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImlhYmRpcHBua25xbW96aGVpdWxiIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MzIwNDExNzQsImV4cCI6MjA0NzYxNzE3NH0.KfZR5YVwVQcrbToe9NzdUvJD8jbkeKOq8DRlM87F0Js";

export const supabase = createClient(SUPABASE_URL, SUPABASE_KEY);

/**
 * Registriert einen neuen Benutzer.
 */
export async function registerUser(email, password, username, role = "customer") {
  try {
    const { data, error } = await supabase.auth.signUp({ email, password });
    if (error) throw error;

    if (!data.user) {
      throw new Error("Benutzer konnte nicht erstellt werden.");
    }

    console.log("Registrierte Benutzer-ID:", data.user.id);
    console.log("Registrierte E-Mail:", email.toLowerCase().trim());

    const { error: userError } = await supabase.from("users").insert({
      id: data.user.id,
      email: email.toLowerCase().trim(),
      username,
      role,
    });

    if (userError) throw userError;

    console.log("Benutzer erfolgreich in der Tabelle users gespeichert.");
    return null; // Erfolgreich
  } catch (error) {
    console.error("Fehler bei der Registrierung:", error.message);
    return `Registrierung fehlgeschlagen: ${error.message}`;
  }
}

/**
 * Loggt einen Benutzer ein.
 */
export async function loginUser(identifier, password) {
  try {
    console.log("Login versucht mit:", identifier, password);

    // Authentifizieren
    const { data, error } = await supabase.auth.signInWithPassword({
      email: identifier.toLowerCase().trim(),
      password,
    });

    if (error) {
      console.error("Fehler bei der Authentifizierung:", error.message);
      return "Invalid login credentials";
    }

    console.log("Login erfolgreich:", data);

    // Benutzerinformationen abrufen
    const { data: userInfo, error: userError } = await supabase
      .from("users")
      .select("username, role")
      .eq("email", identifier.toLowerCase().trim())
      .maybeSingle();

    console.log("Benutzerabfrage-Ergebnis:", userInfo);

    if (userError) {
      console.error("Fehler bei der Benutzerabfrage:", userError.message);
      return "Fehler beim Abrufen der Benutzerinformationen.";
    }

    if (!userInfo) {
      console.error("Keine Benutzerinformationen gefunden.");
      return "Benutzer existiert nicht oder ist nicht registriert.";
    }

    return { user: data.user, role: userInfo.role };
  } catch (error) {
    console.error("Fehler beim Login:", error.message);
    return "Login fehlgeschlagen.";
  }
}

/**
 * Zurücksetzen des Passworts.
 */
export async function resetPassword(email) {
  try {
    const { error } = await supabase.auth.resetPasswordForEmail(email);
    if (error) throw error;
    return null;
  } catch (error) {
    console.error("Fehler beim Zurücksetzen des Passworts:", error.message);
    return "Fehler beim Zurücksetzen des Passworts.";
  }
}

/**
 * Holt alle Termine aus der `appointments`-Tabelle.
 */
export async function fetchAppointments() {
  try {
    const { data, error } = await supabase.from("appointments").select("*");
    if (error) throw error;
    console.log("Termine erfolgreich abgerufen:", data);
    return data;
  } catch (error) {
    console.error("Fehler beim Abrufen der Termine:", error.message);
    return [];
  }
}

/**
 * Fügt einen neuen Termin hinzu.
 */
export async function addAppointment(appointment) {
  try {
    if (!appointment.date || !appointment.time) {
      return { data: null, error: "Datum und Zeit müssen angegeben werden." };
    }

    const datetime = new Date(appointment.date);
    if (isNaN(datetime.getTime())) {
      return { data: null, error: "Ungültiges Datumsformat." };
    }

    // Prüfen auf Doppelbuchung (Datum und Zeit)
    const { data: conflictingAppointments, error: conflictError } = await supabase
      .from("appointments")
      .select("*")
      .eq("date", appointment.date)
      .eq("time", appointment.time);

    if (conflictError) throw conflictError;

    if (conflictingAppointments.length > 0) {
      return {
        data: null,
        error: "Doppelbuchung erkannt. Diese Zeit ist bereits vergeben.",
      };
    }

    // Termin hinzufügen
    const { data, error } = await supabase
      .from("appointments")
      .insert({
        ...appointment,
      })
      .select();

    if (error) throw error;

    return { data, error: null };
  } catch (err) {
    console.error("Fehler beim Hinzufügen des Termins:", err.message);
    return { data: null, error: err.message };
  }
}

/**
 * Aktualisiert einen Termin.
 */
export async function updateAppointment(id, updates) {
  try {
    if (!updates.date || !updates.time) {
      return { data: null, error: "Datum und Zeit müssen angegeben werden." };
    }

    const datetime = new Date(updates.date);
    if (isNaN(datetime.getTime())) {
      return { data: null, error: "Ungültiges Datumsformat." };
    }

    // Überprüfung auf Doppelbuchung (Datum und Zeit)
    const { data: existingAppointments, error: checkError } = await supabase
      .from("appointments")
      .select("*")
      .eq("date", updates.date)
      .eq("time", updates.time)
      .neq("id", id); // Ausschließen des aktuellen Termins

    if (checkError) throw checkError;

    if (existingAppointments.length > 0) {
      return {
        data: null,
        error: "Diese Zeit ist bereits gebucht. Bitte wählen Sie eine andere Zeit.",
      };
    }

    // Termin aktualisieren
    const { data, error } = await supabase
      .from("appointments")
      .update(updates)
      .eq("id", id)
      .select();

    if (error) throw error;

    return { data, error: null };
  } catch (error) {
    console.error("Fehler beim Aktualisieren des Termins:", error.message);
    return { data: null, error: error.message };
  }
}

/**
 * Löscht einen Termin.
 */
export async function deleteAppointment(id) {
  try {
    console.log("Lösche Termin mit ID:", id);

    const { data, error } = await supabase.from("appointments").delete().eq("id", id).select();
    if (error) {
      console.error("Fehler beim Löschen des Termins:", error.message);
      return { data: null, error };
    }

    if (!data || data.length === 0) {
      console.error("Kein Termin gelöscht. Prüfe die ID:", id);
      return { data: null, error: new Error("Kein Termin gelöscht.") };
    }

    console.log("Termin erfolgreich gelöscht:", data);
    return { data, error: null };
  } catch (error) {
    console.error("Fehler beim Löschen des Termins (Catch):", error.message);
    return { data: null, error };
  }
}

/**
 * Holt alle verfügbaren Zeiten für ein bestimmtes Datum aus der `appointments`-Tabelle.
 */
export async function fetchAvailableTimes(date) {
  try {
    const { data: bookedAppointments, error } = await supabase
      .from("appointments")
      .select("time")
      .eq("date", date);

    if (error) throw error;

    const bookedTimes = bookedAppointments.map((appointment) => appointment.time);

    // Liste aller möglichen Zeiten
    const allTimes = ["19:00", "19:30", "20:00", "20:30", "21:00", "21:30"];
    
    // Verfügbare Zeiten berechnen
    const availableTimes = allTimes.filter((time) => !bookedTimes.includes(time));
    return { availableTimes, error: null };
  } catch (error) {
    console.error("Fehler beim Abrufen der verfügbaren Zeiten:", error.message);
    return { availableTimes: [], error: error.message };
  }
}
