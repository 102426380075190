import React, { useState, useEffect } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { useNavigate } from "react-router-dom";
import { supabase } from "../supabase"; // Import Supabase Client
import "../AppointmentForm.css";

function AppointmentForm({ onLogout }) {
  const navigate = useNavigate();
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [time, setTime] = useState("");
  const [service, setService] = useState("");
  const [availableTimes, setAvailableTimes] = useState([]);
  const [existingAppointment, setExistingAppointment] = useState(null);
  const [username, setUsername] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [blockedDates, setBlockedDates] = useState([]);

  useEffect(() => {
    const checkAuthentication = async () => {
      const {
        data: { user },
        error: authError,
      } = await supabase.auth.getUser();

      if (!user || authError) {
        setError("Benutzer ist nicht authentifiziert.");
        navigate("/login");
        return;
      }

      try {
        const { data: userProfile, error: profileError } = await supabase
          .from("users")
          .select("username")
          .eq("id", user.id)
          .single();

        if (profileError || !userProfile) {
          setError("Benutzername konnte nicht gefunden werden.");
        } else {
          setUsername(userProfile.username);
        }
      } catch (err) {
        console.error("Fehler beim Abrufen des Benutzernamens:", err);
        setError("Fehler beim Abrufen des Benutzernamens.");
      } finally {
        setLoading(false);
      }
    };

    checkAuthentication();
    fetchBlockedDates();
  }, [navigate]);

  useEffect(() => {
    if (username) {
      fetchAvailableTimes();
      checkExistingAppointment();
    }
  }, [selectedDate, username]);

  const fetchBlockedDates = async () => {
    try {
      const { data, error } = await supabase.from("blocked_dates").select("date");
      if (error) throw error;
      setBlockedDates(data.map((entry) => new Date(entry.date)));
    } catch (err) {
      console.error("Fehler beim Abrufen der gesperrten Tage:", err);
      setError("Fehler beim Abrufen der gesperrten Tage.");
    }
  };

  const fetchAvailableTimes = async () => {
    try {
      const start = new Date(selectedDate);
      start.setHours(19, 0, 0);
      const end = new Date(selectedDate);
      end.setHours(21, 30, 0);

      const { data: appointments, error } = await supabase
        .from("appointments")
        .select("time, date")
        .gte("date", start.toISOString())
        .lte("date", end.toISOString());

      if (error) throw error;

      const bookedTimes = appointments.map((appt) => appt.time);
      const slots = generateTimeSlots(start, end).filter(
        (slot) => !bookedTimes.includes(slot)
      );
      setAvailableTimes(slots);
    } catch (err) {
      console.error("Fehler beim Laden der verfügbaren Zeiten:", err);
      setError("Fehler beim Laden der verfügbaren Zeiten.");
    }
  };

  const checkExistingAppointment = async () => {
    if (!username) return;

    try {
      const { data: appointment, error } = await supabase
        .from("appointments")
        .select("*")
        .eq("name", username)
        .single();

      if (error && error.code !== "PGRST116") {
        throw error;
      }

      setExistingAppointment(appointment || null);
    } catch (err) {
      console.error("Fehler beim Überprüfen des bestehenden Termins:", err);
      setError("Fehler beim Überprüfen des bestehenden Termins.");
    }
  };

  const generateTimeSlots = (start, end) => {
    const slots = [];
    let current = new Date(start);
    while (current <= end) {
      slots.push(current.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" }));
      current.setMinutes(current.getMinutes() + 30);
    }
    return slots;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setSuccess("");
  
    const userResponse = await supabase.auth.getUser();
    const user = userResponse.data?.user;
  
    if (!user) {
      setError("Benutzer ist nicht authentifiziert. Bitte melden Sie sich erneut an.");
      return;
    }
  
    if (!username) {
      setError("Benutzername konnte nicht geladen werden. Bitte laden Sie die Seite neu.");
      return;
    }
  
    if (!time || !service) {
      setError("Bitte wählen Sie eine Zeit und Dienstleistung aus.");
      return;
    }
  
    try {
      const selectedDateStr = selectedDate.toLocaleDateString("en-CA"); // Format: YYYY-MM-DD
  
      // Überprüfen, ob das Datum blockiert ist
      const isBlocked = blockedDates.some(
        (blockedDate) => blockedDate.toLocaleDateString("en-CA") === selectedDateStr
      );
  
      if (isBlocked) {
        setError("Dieser Tag ist blockiert. Bitte wählen Sie ein anderes Datum.");
        return;
      }
  
      const selectedDateTime = new Date(selectedDate);
      const [hours, minutes] = time.split(":").map(Number);
      selectedDateTime.setHours(hours, minutes, 0, 0);
  
      const { data: existingAppointments, error: fetchError } = await supabase
        .from("appointments")
        .select("*")
        .eq("user_id", user.id)
        .eq("date", selectedDateTime.toISOString().split("T")[0]);
  
      if (fetchError) throw fetchError;
  
      if (existingAppointments.length > 0) {
        setError(
          "Sie haben bereits einen Termin an diesem Tag. Bitte stornieren Sie den bestehenden Termin, bevor Sie einen neuen buchen."
        );
        return;
      }
  
      const { error } = await supabase.from("appointments").insert([
        {
          user_id: user.id,
          name: username,
          time,
          service,
          date: selectedDateTime.toISOString(),
        },
      ]);
  
      if (error) {
        if (error.code === "23505") {
          setError("Doppelbuchung nicht möglich. Diese Zeit ist bereits belegt.");
        } else {
          throw error;
        }
      } else {
        setTime("");
        setService("");
        setSuccess("Termin erfolgreich gebucht!");
        checkExistingAppointment();
      }
    } catch (err) {
      console.error("Fehler beim Buchen des Termins:", err);
      setError("Fehler beim Buchen des Termins. Bitte versuchen Sie es erneut.");
    }
  };
  

  const handleDeleteAppointment = async () => {
    if (!existingAppointment) return;

    try {
      const { error } = await supabase
        .from("appointments")
        .delete()
        .eq("id", existingAppointment.id);

      if (error) throw error;

      setExistingAppointment(null);
      setSuccess("Ihr bestehender Termin wurde erfolgreich gelöscht.");
    } catch (err) {
      console.error("Fehler beim Löschen des Termins:", err);
      setError("Fehler beim Löschen des Termins. Bitte versuchen Sie es erneut.");
    }
  };

  const handleDateChange = (date) => {
    const maxDate = new Date();
    maxDate.setDate(maxDate.getDate() + 7);
    if (isWeekday(date) && date <= maxDate) {
      setSelectedDate(date);
      setSuccess("");
      setError("");
    } else if (!isWeekday(date)) {
      setError("Bitte wählen Sie einen Wochentag (Montag bis Freitag).");
    } else {
      setError("Sie können nur eine Woche im Voraus buchen.");
    }
  };

  const isWeekday = (date) => {
    const day = date.getDay();
    return day !== 0 && day !== 6;
  };

  const handleLogout = async () => {
    await supabase.auth.signOut();
    onLogout();
    navigate("/login");
  };

  return (
    <div className="appointment-form container">
      <div className="header">
        <h2>Termin buchen</h2>
        <button className="logout-button" onClick={handleLogout}>
          Logout
        </button>
      </div>
      {error && <p className="error">{error}</p>}
      {success && <p className="success">{success}</p>}
      {loading ? (
        <p>Lade Benutzerdaten...</p>
      ) : existingAppointment ? (
        <div className="existing-appointment">
          <p>
            Sie haben einen Termin am{" "}
            {new Date(existingAppointment.date).toLocaleDateString()} um{" "}
            {existingAppointment.time}.
          </p>
          <button
            className="delete-button bg-red-500 text-white py-2 px-4 rounded hover:bg-red-600"
            onClick={handleDeleteAppointment}
          >
            Termin löschen
          </button>
        </div>
      ) : (
        <>
          <Calendar
            onChange={handleDateChange}
            value={selectedDate}
            minDate={new Date()}
            maxDate={new Date(Date.now() + 7 * 24 * 60 * 60 * 1000)}
            tileDisabled={({ date }) =>
              blockedDates.some((blockedDate) => blockedDate.toISOString() === date.toISOString())
            }
          />
          <form onSubmit={handleSubmit} className="mt-4 space-y-4">
            <select
              value={time}
              onChange={(e) => setTime(e.target.value)}
              required
              className="w-full p-2 border border-gray-300 rounded"
            >
              <option value="">Zeit wählen</option>
              {availableTimes.map((slot) => (
                <option key={slot} value={slot}>
                  {slot}
                </option>
              ))}
            </select>
            <input
              type="text"
              placeholder="Dienstleistung"
              value={service}
              onChange={(e) => setService(e.target.value)}
              required
              className="w-full p-2 border border-gray-300 rounded"
            />
            <button
              type="submit"
              className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 w-full"
            >
              Buchen
            </button>
          </form>
        </>
      )}
    </div>
  );
}

export default AppointmentForm;
