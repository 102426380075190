import React, { useState, useEffect } from "react";
import {
  fetchAppointments,
  deleteAppointment,
  updateAppointment,
} from "../supabase";
//import "../AppStyles.css";
import "../AdminPanel.css";
import { useNavigate } from "react-router-dom";
import { supabase } from "../supabase";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";

function AdminPanel({ onLogout }) {
  const [appointments, setAppointments] = useState([]);
  const [error, setError] = useState("");
  const [editMode, setEditMode] = useState(false);
  const [editingAppointment, setEditingAppointment] = useState(null);
  const [formData, setFormData] = useState({
    time: "",
    service: "",
    date: "",
  });
  const [blockedDates, setBlockedDates] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [availableTimes, setAvailableTimes] = useState([]);
  const [availableDates, setAvailableDates] = useState([]);
  const [userRole, setUserRole] = useState(null);
  const allTimes = ["19:00", "19:30", "20:00", "20:30", "21:00", "21:30"];
  const navigate = useNavigate();

  useEffect(() => {
    const checkAdminAccess = async () => {
      try {
        const {
          data: { user },
          error: authError,
        } = await supabase.auth.getUser();

        if (!user || authError) {
          setError("Benutzer ist nicht authentifiziert.");
          navigate("/login");
          return;
        }

        const { data: userInfo, error } = await supabase
          .from("users")
          .select("role")
          .eq("id", user.id)
          .single();

        if (error) {
          setError("Benutzerrolle konnte nicht geladen werden.");
          navigate("/login");
        } else if (userInfo.role !== "admin") {
          setError("Sie haben keine Berechtigung, auf diese Seite zuzugreifen.");
          navigate("/login");
        } else {
          setUserRole("admin");
        }
      } catch (err) {
        setError("Fehler beim Authentifizieren des Benutzers.");
        navigate("/login");
      }
    };

    checkAdminAccess();
    fetchAppointmentsFromDB();
    fetchBlockedDates();
    generateAvailableDates();
  }, [navigate]);

  useEffect(() => {
    if (editMode && editingAppointment) {
      setFormData({
        time: editingAppointment.time,
        service: editingAppointment.service,
        date: new Date(editingAppointment.date).toISOString().split("T")[0],
      });
      filterAvailableTimes(editingAppointment.date, editingAppointment.id);
    }
  }, [editMode, editingAppointment]);

  const fetchAppointmentsFromDB = async () => {
    try {
      const data = await fetchAppointments();

      // Mapping für Wochentage
      const weekdays = ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"];

      // Sortiere die Termine nach Datum und Zeit
      const sortedAppointments = (data || [])
        .map((appointment) => {
          const dateObj = new Date(appointment.date);
          const weekday = weekdays[dateObj.getDay()]; // Wochentag aus Datum extrahieren
          return {
            ...appointment,
            weekday, // Füge Wochentag hinzu
          };
        })
        .sort((a, b) => {
          const dateA = new Date(a.date).getTime();
          const dateB = new Date(b.date).getTime();
          if (dateA === dateB) {
            return a.time.localeCompare(b.time); // Sortiere nach Zeit, wenn Datum gleich ist
          }
          return dateA - dateB;
        });

      setAppointments(sortedAppointments);
    } catch (err) {
      console.error("Fehler beim Abrufen der Termine:", err);
      setError("Fehler beim Laden der Termine.");
    }
  };

  const fetchBlockedDates = async () => {
    try {
      const { data, error } = await supabase.from("blocked_dates").select("date");
      if (error) throw error;
      setBlockedDates(data.map((entry) => new Date(entry.date)));
    } catch (err) {
      setError("Fehler beim Abrufen der gesperrten Tage.");
    }
  };

  const generateAvailableDates = async () => {
    try {
      const bookedDates = new Set(
        appointments.map((appt) => appt.date)
      );

      const today = new Date();
      const next7Days = [];
      for (let i = 0; i < 7; i++) {
        const date = new Date();
        date.setDate(today.getDate() + i);
        if (isWeekday(date)) {
          const dateString = date.toISOString().split("T")[0];
          if (!bookedDates.has(dateString)) {
            next7Days.push(date);
          }
        }
      }

      setAvailableDates(next7Days);
    } catch (err) {
      setError("Fehler beim Laden der verfügbaren Tage.");
    }
  };

  const filterAvailableTimes = async (date, excludeAppointmentId = null) => {
    try {
      const { data: bookedTimes, error } = await supabase
        .from("appointments")
        .select("time")
        .eq("date", date)
        .neq("id", excludeAppointmentId);

      if (error) {
        setError("Fehler beim Abrufen der verfügbaren Zeiten.");
        return;
      }

      const booked = bookedTimes.map((appt) => appt.time);
      const freeTimes = allTimes.filter((time) => !booked.includes(time));
      setAvailableTimes(freeTimes);
    } catch (err) {
      console.error("Fehler beim Filtern der verfügbaren Zeiten:", err);
      setError("Fehler beim Laden der verfügbaren Zeiten.");
    }
  };

  const isWeekday = (date) => {
    const day = date.getDay();
    return day !== 0 && day !== 6;
  };

  const handleEdit = (appointment) => {
    setEditMode(true);
    setEditingAppointment(appointment);
    filterAvailableTimes(appointment.date, appointment.id);
  };

  const handleBlockDate = async () => {
    try {
      // Zeitzonen-Korrektur, um nur das Datum zu erhalten
      const localDate = new Date(
        selectedDate.getTime() - selectedDate.getTimezoneOffset() * 60000
      );
      const dateStr = localDate.toISOString().split("T")[0]; // Nur Datum (YYYY-MM-DD)
  
      // Überprüfen, ob das Datum bereits gesperrt ist
      const { data: existing, error: fetchError } = await supabase
        .from("blocked_dates")
        .select("date")
        .eq("date", dateStr);
  
      if (fetchError) {
        console.error("Fehler beim Abrufen gesperrter Tage:", fetchError);
        setError("Fehler beim Abrufen bestehender gesperrter Tage.");
        return;
      }
  
      if (existing.length > 0) {
        setError("Dieser Tag ist bereits gesperrt.");
        return;
      }
  
      // Neues Datum hinzufügen
      const { error } = await supabase.from("blocked_dates").insert([{ date: dateStr }]);
      if (error) {
        console.error("Fehler beim Sperren des Tages:", error);
        setError("Fehler beim Sperren des Tages.");
        return;
      }
  
      // Gesperrten Tag lokal hinzufügen
      setBlockedDates((prev) => [...prev, localDate]);
      setError("");
      console.log("Tag erfolgreich gesperrt:", dateStr);
    } catch (err) {
      console.error("Allgemeiner Fehler beim Sperren des Tages:", err);
      setError("Allgemeiner Fehler beim Sperren des Tages.");
    }
  };
  
  const handleUnblockDate = async (dateToUnblock) => {
    try {
      // Zeitzonen-Korrektur, um nur das richtige Datum zu erhalten
      const localDate = new Date(
        dateToUnblock.getTime() - dateToUnblock.getTimezoneOffset() * 60000
      );
      const dateStr = localDate.toISOString().split("T")[0]; // Nur Datum (YYYY-MM-DD)
  
      // Entfernen des gesperrten Datums aus der Datenbank
      const { error } = await supabase.from("blocked_dates").delete().eq("date", dateStr);
  
      if (error) {
        console.error("Fehler beim Entsperren des Tages:", error);
        setError("Fehler beim Entsperren des Tages.");
        return;
      }
  
      // Entfernen des Datums aus dem lokalen State
      setBlockedDates((prev) =>
        prev.filter((date) => {
          const localPrevDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000);
          return localPrevDate.toISOString().split("T")[0] !== dateStr;
        })
      );
  
      setError("");
      console.log("Tag erfolgreich entsperrt:", dateStr);
    } catch (err) {
      console.error("Allgemeiner Fehler beim Entsperren des Tages:", err);
      setError("Allgemeiner Fehler beim Entsperren des Tages.");
    }
  };
  

  const handleSave = async () => {
    try {
      if (!editingAppointment) return;

      const updates = {
        time: formData.time,
        service: formData.service,
        date: new Date(formData.date).toISOString(),
      };

      const { data, error } = await updateAppointment(editingAppointment.id, updates);

      if (error) {
        if (error.message.includes("unique_date_time_per_appointment")) {
          setError("Die gewählte Zeit ist bereits gebucht. Bitte wählen Sie eine andere Zeit.");
        } else {
          throw new Error(error.message);
        }
        return;
      }

      setAppointments((prev) =>
        prev.map((appt) =>
          appt.id === editingAppointment.id ? { ...appt, ...updates } : appt
        )
      );

      setEditMode(false);
      setEditingAppointment(null);
      setFormData({ time: "", service: "", date: "" });
      setError("");
    } catch (err) {
      setError("Fehler beim Speichern der Änderungen.");
    }
  };

  const handleCancel = () => {
    setEditMode(false);
    setEditingAppointment(null);
    setFormData({ time: "", service: "", date: "" });
    setAvailableTimes([]);
  };

  const handleDelete = async (appointmentId) => {
    try {
      // Zeige dem Nutzer, dass der Löschvorgang läuft
      setError(""); // Reset vorherige Fehler
      setAppointments((prev) =>
        prev.map((appt) =>
          appt.id === appointmentId ? { ...appt, deleting: true } : appt
        )
      );
  
      // Versuche, den Termin zu löschen
      const { error } = await deleteAppointment(appointmentId);
  
      if (error) {
        throw new Error(error.message);
      }
  
      // Entferne den Termin aus der Liste, wenn erfolgreich
      setAppointments((prev) => prev.filter((appt) => appt.id !== appointmentId));
    } catch (err) {
      setError("Fehler beim Löschen des Termins. Bitte versuchen Sie es erneut.");
      console.error("Löschfehler:", err.message);
    } finally {
      // Entferne den "deleting"-Status
      setAppointments((prev) =>
        prev.map((appt) =>
          appt.id === appointmentId ? { ...appt, deleting: false } : appt
        )
      );
    }
  };
  

  const handleLogout = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("isAdmin");
    onLogout();
    navigate("/login");
  };

  if (!userRole) {
    return <p>Lade Admin-Berechtigungen...</p>;
  }

  return (
    <div className="admin-panel-wrapper">
  <div className="admin-panel">
    <div className="header">
      <h2>Admin Panel</h2>
      <button className="logout-button" onClick={handleLogout}>
        Logout
      </button>
    </div>
    
      {error && <p className="error">{error}</p>}

      <div className="calendar-section">
  <h3>Gesperrte Tage verwalten</h3>
  <Calendar
    onChange={setSelectedDate}
    value={selectedDate}
    tileClassName={({ date }) => {
      const localDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000);
      return blockedDates.some(
        (blockedDate) =>
          new Date(blockedDate.getTime() - blockedDate.getTimezoneOffset() * 60000)
            .toISOString()
            .split("T")[0] === localDate.toISOString().split("T")[0]
      )
        ? "blocked-date"
        : "";
    }}
  />
  <div className="button-group">
    {/* Button zum Blockieren eines Datums */}
    <button onClick={handleBlockDate} className="block-button">
      Tag sperren
    </button>

    {/* Button zum Entsperren eines Datums */}
    <button
      onClick={() => handleUnblockDate(selectedDate)}
      disabled={
        !blockedDates.some(
          (blockedDate) =>
            new Date(blockedDate.getTime() - blockedDate.getTimezoneOffset() * 60000)
              .toISOString()
              .split("T")[0] ===
            new Date(selectedDate.getTime() - selectedDate.getTimezoneOffset() * 60000)
              .toISOString()
              .split("T")[0]
        )
      }
      className="unblock-button"
    >
      Tag entsperren
    </button>
  </div>
</div>


      {editMode ? (
        <div className="form-section">
          <h3>Termin bearbeiten</h3>
          <select
            value={formData.date}
            onChange={(e) => {
              setFormData({ ...formData, date: e.target.value });
              filterAvailableTimes(e.target.value, editingAppointment?.id);
            }}
          >
            <option value="">Datum wählen</option>
            {availableDates.map((date) => (
              <option key={date.toISOString()} value={date.toISOString().split("T")[0]}>
                {date.toLocaleDateString("de-DE")}
              </option>
            ))}
          </select>
          <select
            value={formData.time}
            onChange={(e) => setFormData({ ...formData, time: e.target.value })}
          >
            <option value="">Zeit wählen</option>
            {availableTimes.map((time) => (
              <option key={time} value={time}>
                {time}
              </option>
            ))}
          </select>
          <input
            type="text"
            placeholder="Dienstleistung"
            value={formData.service}
            onChange={(e) => setFormData({ ...formData, service: e.target.value })}
          />
          <button onClick={handleSave}>Speichern</button>
          <button onClick={handleCancel}>Abbrechen</button>
        </div>
      ) : (
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Datum</th>
              <th>Wochentag</th>
              <th>Zeit</th>
              <th>Dienstleistung</th>
              <th>Aktionen</th>
            </tr>
          </thead>
          <tbody>
            {appointments.length > 0 ? (
              appointments.map((appointment) => (
                <tr key={appointment.id}>
                  <td>{appointment.name || "N/A"}</td>
                  <td>{new Date(appointment.date).toLocaleDateString("de-DE")}</td>
                  <td>{appointment.weekday}</td>
                  <td>{appointment.time}</td>
                  <td>{appointment.service}</td>
                  <td>
                    <button
                      onClick={() => handleEdit(appointment)}
                      title="Bearbeiten"
                      className="icon-button"
                    >
                      <i className="fas fa-pencil-alt"></i>
                    </button>
                    <button
                      onClick={() => handleDelete(appointment.id)}
                      title="Löschen"
                      className="icon-button"
                    >
                      <i className="fas fa-trash-alt"></i>
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="6" className="no-data">
                  Keine Buchungen vorhanden
                </td>
              </tr>
            )}
          </tbody>
        </table>
      )}
    </div>
    </div>
  );
}

export default AdminPanel;
