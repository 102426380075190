import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import Login from "./components/Login";
import AdminPanel from "./components/AdminPanel";
import AppointmentForm from "./components/AppointmentForm";

import "./AppStyles.css";

function App() {
  const [user, setUser] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);

  // Lade persistente Daten aus localStorage
  useEffect(() => {
    const savedUser = JSON.parse(localStorage.getItem("user"));
    const savedIsAdmin = JSON.parse(localStorage.getItem("isAdmin"));

    console.log("Initial User State:", savedUser, savedIsAdmin);

    if (savedUser) {
      setUser(savedUser);
      setIsAdmin(savedIsAdmin);
    }
  }, []);

  const handleLogin = (userInfo) => {
    console.log("Login Successful:", userInfo);
    setUser(userInfo.user);
    setIsAdmin(userInfo.isAdmin);
    localStorage.setItem("user", JSON.stringify(userInfo.user));
    localStorage.setItem("isAdmin", JSON.stringify(userInfo.isAdmin));
  };

  const handleLogout = () => {
    console.log("Logout Triggered");
    setUser(null);
    setIsAdmin(false);
    localStorage.removeItem("user");
    localStorage.removeItem("isAdmin");
  };

  return (
    <Router>
      <div className="App">
        <Routes>
          {/* Debugging für Root Route */}
          <Route
            path="/"
            element={
              user ? (
                <Navigate to={isAdmin ? "/admin" : "/appointments"} replace />
              ) : (
                <Navigate to="/login" replace />
              )
            }
          />
          <Route
            path="/login"
            element={
              user ? (
                <Navigate to={isAdmin ? "/admin" : "/appointments"} replace />
              ) : (
                <Login onLogin={handleLogin} />
              )
            }
          />
          <Route
            path="/admin"
            element={
              user && isAdmin ? (
                <AdminPanel onLogout={handleLogout} />
              ) : (
                <Navigate to="/login" replace />
              )
            }
          />
          <Route
            path="/appointments"
            element={
              user && !isAdmin ? (
                <AppointmentForm onLogout={handleLogout} />
              ) : (
                <Navigate to="/login" replace />
              )
            }
          />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
