import React, { useState } from "react";
import { loginUser, resetPassword, registerUser } from "../supabase"; // Importiere die Supabase-Funktionen
import "../Login.css";

function Login({ onLogin }) {
  const [identifier, setIdentifier] = useState("");
  const [password, setPassword] = useState("");
  const [registerUsername, setRegisterUsername] = useState("");
  const [registerEmail, setRegisterEmail] = useState("");
  const [registerPassword, setRegisterPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [authCode, setAuthCode] = useState("");
  const [showRegister, setShowRegister] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [resetEmail, setResetEmail] = useState("");
  const [message, setMessage] = useState("");
  const [showPassword, setShowPassword] = useState(false); // Sichtbarkeit des Passworts (Login)
  const [showRegisterPassword, setShowRegisterPassword] = useState(false); // Sichtbarkeit beim Registrieren
  const [showConfirmPassword, setShowConfirmPassword] = useState(false); // Sichtbarkeit für Passwort wiederholen
  const [showPopup, setShowPopup] = useState(false); // Kontrolliert das Pop-up

  const VALID_AUTH_CODE = "20041998";

  const togglePasswordVisibility = () => setShowPassword(!showPassword);
  const toggleRegisterPasswordVisibility = () =>
    setShowRegisterPassword(!showRegisterPassword);
  const toggleConfirmPasswordVisibility = () =>
    setShowConfirmPassword(!showConfirmPassword);

  const handleLogin = async (e) => {
    e.preventDefault();
    setError("");
    try {
      const result = await loginUser(identifier, password); // Login-Funktion aus `supabase.js`
      if (typeof result === "string") {
        setError(result); // Fehler anzeigen
      } else {
        const { user, role } = result;
        onLogin({ user, isAdmin: role === "admin" }); // Benutzer und Rolle speichern
      }
    } catch (err) {
      setError("Login fehlgeschlagen. Bitte überprüfen Sie Ihre Eingaben.");
      console.error("Login-Fehler:", err);
    }
  };

  const handleRegister = async (e) => {
    e.preventDefault();
    setError("");
    setSuccess("");
  
    if (registerPassword !== confirmPassword) {
      setError("Die Passwörter stimmen nicht überein.");
      return;
    }
  
    if (authCode !== VALID_AUTH_CODE) {
      setError("Ungültiger Authentifizierungscode.");
      return;
    }
  
    try {
      const result = await registerUser(
        registerEmail,
        registerPassword,
        registerUsername,
        "customer"
      );
  
      if (result) {
        setError(result); // Zeigt Registrierungsfehler an
      } else {
        // Automatischer Login nach erfolgreicher Registrierung
        const loginResult = await loginUser(registerEmail, registerPassword);
        if (typeof loginResult === "string") {
          setError(loginResult); // Login-Fehler anzeigen
        } else {
          const { user, role } = loginResult;
          onLogin({ user, isAdmin: role === "admin" }); // Benutzer einloggen
          setShowPopup(true); // Erfolgreiche Registrierung anzeigen
        }
      }
  
      // Felder zurücksetzen
      setRegisterUsername("");
      setRegisterEmail("");
      setRegisterPassword("");
      setConfirmPassword("");
      setAuthCode("");
      setShowRegister(false);
    } catch (err) {
      setError("Registrierung fehlgeschlagen. Bitte versuchen Sie es erneut.");
      console.error("Registrierungs-Fehler:", err);
    }
  };
  

  const handleResetPassword = async (e) => {
    e.preventDefault();
    setError("");
    setMessage("");
    try {
      const result = await resetPassword(resetEmail);
      if (result) {
        setError(result);
      } else {
        setMessage("Passwort-Wiederherstellungs-E-Mail wurde gesendet.");
      }
    } catch (err) {
      setError("Fehler beim Zurücksetzen des Passworts.");
      console.error("Passwort-Reset-Fehler:", err);
    }
  };

  return (
    <div className="login">
      {showPopup && (
        <div className="popup">
          <div className="popup-content">
            <h3>Registrierung abgeschlossen!</h3>
            <p>Sie können sich jetzt einloggen</p>
            <button onClick={() => setShowPopup(false)}>Schließen</button>
          </div>
        </div>
      )}
      {showRegister ? (
        <>
          <h2>Registrieren</h2>
          {error && <p className="error">{error}</p>}
          {success && <p className="success">{success}</p>}
          <form onSubmit={handleRegister}>
            <input
              type="text"
              placeholder="Benutzername (für db-Abgleich)"
              value={registerUsername}
              onChange={(e) => setRegisterUsername(e.target.value)}
              required
            />
            <input
              type="email"
              placeholder="E-Mail"
              value={registerEmail}
              onChange={(e) => setRegisterEmail(e.target.value)}
              required
            />
            <div className="password-container">
              <input
                type={showRegisterPassword ? "text" : "password"}
                placeholder="Passwort"
                value={registerPassword}
                onChange={(e) => setRegisterPassword(e.target.value)}
                required
              />
              <span
                className="eye-icon"
                onClick={toggleRegisterPasswordVisibility}
              >
                <i
                  className={`fas ${
                    showRegisterPassword ? "fa-eye-slash" : "fa-eye"
                  }`}
                ></i>
              </span>
            </div>
            <div className="password-container">
              <input
                type={showConfirmPassword ? "text" : "password"}
                placeholder="Passwort wiederholen"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
              />
              <span
                className="eye-icon"
                onClick={toggleConfirmPasswordVisibility}
              >
                <i
                  className={`fas ${
                    showConfirmPassword ? "fa-eye-slash" : "fa-eye"
                  }`}
                ></i>
              </span>
            </div>
            <input
              type="text"
              placeholder="Authentifizierungscode"
              value={authCode}
              onChange={(e) => setAuthCode(e.target.value)}
              required
            />
            <button type="submit">Registrieren</button>
          </form>
          <button
            className="toggle-login"
            onClick={() => setShowRegister(false)}
          >
            Bereits einen Account? Jetzt einloggen
          </button>
        </>
      ) : (
        <>
          <h2>Login</h2>
          {error && <p className="error">{error}</p>}
          {message && <p className="message">{message}</p>}
          <form onSubmit={handleLogin}>
            <input
              type="text"
              placeholder="E-Mail"
              value={identifier}
              onChange={(e) => setIdentifier(e.target.value)}
              required
            />
            <div className="password-container">
              <input
                type={showPassword ? "text" : "password"}
                placeholder="Passwort"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              <span className="eye-icon" onClick={togglePasswordVisibility}>
                <i
                  className={`fas ${
                    showPassword ? "fa-eye-slash" : "fa-eye"
                  }`}
                ></i>
              </span>
            </div>
            <button type="submit">Einloggen</button>
          </form>
          <button
            className="forgot-password"
            onClick={() => setShowRegister(true)}
          >
            Noch keinen Account? Jetzt registrieren
          </button>
          <form onSubmit={handleResetPassword}>
            <input
              type="email"
              placeholder="E-Mail für Passwort zurücksetzen"
              value={resetEmail}
              onChange={(e) => setResetEmail(e.target.value)}
            />
            <button type="submit">Passwort zurücksetzen</button>
          </form>
        </>
      )}
      {/* Impressum */}
      <footer className="impressum">
        <p>
          <strong>Impressum:</strong> Diese Anwendung wurde privat erstellt von{" "}
          <em>Dominik Djohar Feltes</em>, Kernstrasse 2, 75334 Straubenhardt.
          Kontakt: <a href="mailto:uclmv@web.de">uclmv@web.de</a>
        </p>
      </footer>
    </div>
  );
}

export default Login;
